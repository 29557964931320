
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #717171;
}
.toast-message a:hover {
  color: #717171;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: 30px;
  top: -16px;
  font-weight: 100;
  float: right;
  font-size: 35px;
  color: #717171;
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  height: auto;
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 30px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  font-size: 16px;
  color: #717171;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info:before {
  font-family: "FontAwesome"; font-weight: 400; content: "\f05a";
  display: block;
  color: rgb(70, 30, 125);
  font-size: 25px;
  float: left;
  margin: -7px 12px 0 0;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error:before {
  font-family: "FontAwesome"; font-weight: 400; content: "\f071";
  display: block;
  color: rgb(252, 75, 136);
  font-size: 25px;
  float: left;
  margin: -7px 12px 0 0;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success:before {
  font-family: "FontAwesome"; font-weight: 400; content: "\f058";
  display: block;
  color: rgb(89,188,95);
  font-size: 25px;
  float: left;
  margin: -7px 12px 0 0;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning:before {
  font-family: "FontAwesome"; font-weight: 400; content: "\f06a";
  display: block;
  color: rgb(254, 204, 50);
  font-size: 25px;
  float: left;
  margin: -7px 12px 0 0;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 100%;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: rgb(215, 241, 216);
}
.toast-error {
  background-color: rgb(254, 220, 233);
}
.toast-info {
  background-color: rgb(224, 218, 242);;
}
.toast-warning {
  background-color: rgb(255, 248, 218);
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
