$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";
@import "checkbox";
@import "radiobutton";
@import 'toaster';

@font-face {
  font-family: "RoobertENEL-Bold";
  src: url("./assets/fonts/RoobertENEL-Bold.woff2"); }

@font-face {
  font-family: "RoobertENEL";
  src: url("./assets/fonts/RoobertENEL-Regular.woff2");}

@font-face {
  font-family: "RoobertENEL-Light";
  src: url("./assets/fonts/RoobertENEL-Light.woff2");}

body, html {
  height: 100%;
}

body {
  font-family: RoobertENEL-Light, sans-serif;
  background-color: #f6f6f6;
  font-size: 14px;

}

label {
  margin-bottom: 0;
}
.global-footer{
  margin-top: 20px;
}

h1 {
  color: #461e7d;
  font-family: RoobertENEL-Light, sans-serif;
  font-size: 21px;
}

h2 {
  color: #333333;
  font-family: RoobertENEL-Light, sans-serif;
  font-size: 18px;
}

.ovh {
  overflow: hidden;
}


.centered {
  text-align: center;
}

.parentFlex {
  display: flex;
  flex-direction: column;
}
.webpage {
  padding: 2%;
}

.input-label {
  display: block;
  font-family: RoobertENEL-Light, sans-serif;
  font-size: 14px;
  color: #717171;
}

.inline {
  display: inline-block;
  margin-right: 15px;
}

// Custom select display
.select-wrapper{
  position: relative;
  display: inline-block;

  font-size: 14px;
  color: #333333;

  .select-input{
    -webkit-appearance: none;
    border: 1px solid #D4D4D4;
    background-color: #fff;
    border-radius: 0;
    padding-right: 36px;
    margin-top: 6px;
    padding-left: 15px;
    font-size: 14px;
    width: 100%;
    line-height: 36px;

    &::-ms-expand{
      display: none;
    }

    &:disabled{
      color: graytext;
    }
  }

  &:after{
    content: "\f078";
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 15px;
    pointer-events: none;
    font-family: FontAwesome;
    color: #333333;

  }
}
.form-element {
  line-height: 36px;
  border: 1px solid #D4D4D4;
  padding-left: 15px;
}

.primary-action, .primary-action-button {
  box-shadow: none;
  padding: .6em 2.2em;
  cursor: pointer;
  background: #461E7D;
  transition: .5s;
  border-radius: 40px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  display: inline-block; }

.primary-action:hover, .primary-action-button:hover {
  background: #6e46a5;
  color: white;
}

.primary-action:active, .primary-action-button:active {
  background: #6e46a5;
  color: white;
}

.primary-action:disabled, .primary-action-button:disabled {
  background: #666666;
  color: #F4F4F6;
  cursor: not-allowed;
}


.btn {
  box-shadow: none;
  padding: .6em 2.2em;
  cursor: pointer;
  background: #0085B9;
  border: 1px solid #0085B9;
  transition:.5s;
  border-radius: 3px;
  color: white;
  font-size:14px;
  font-weight:500;
  min-width: 68px;
  line-height: .5 !important;
  margin: 5px;

}
.btn:hover {
  background: #00A3E2;
  border: 1px solid #00A3E2;
}
.btn:active {
  background: #00A3E2;
  border: 1px solid #00A3E2;
}
.btn:disabled{
  background: #D8DADC;
  border: 1px solid #D8DADC;
  color: #999999;
  cursor:not-allowed;
}
.btn2 {
  box-shadow: none;
  padding: .6em 2.2em;
  cursor: pointer;
  background: none;
  border: 1px solid #0085B9;
  transition:.5s;
  border-radius: 3px;
  color:#0085B9;
  font-size:14px;
  font-weight:500;

}
.btn2:hover {
  background: #E5F2F8;
  border: 1px solid #0085B9;
}
.btn2:active {
  background: #E5F2F8;
  border: 1px solid #0085B9;
}
.btn2:disabled{
  background: none;
  border: 1px solid #B2B6B9;
  color: #B2B6B9;
  cursor:not-allowed;
}

#workflow-config-table {
  padding: 5%;
}

.table {
  td {
    vertical-align: middle !important;
  }

  table-layout: fixed;
  margin: 2% 0;
  width: 100%;
  min-width: 1200px;
  border: solid #D4D4D4;
  border-width: 1px;
}

.table thead tr th {
  padding: 10px;
}

.table tr {
  height: 45px;
  padding-left: 30px;
}
.table tr:nth-child(even) {
  background-color: #f5f4fb;
}

.groupTitleRow {
  background-color: #461E7D;
  text-align: center;
  color: white;
  font-weight: bold;
}

.table thead {
  background-color: white;
  border: solid #D4D4D4;
  border-width: 1px 0;
  color: #717171
}

.minutes-input {
  display:block;
  width: 100%;
}

.no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  -moz-appearance:textfield !important;
}

.radio-label {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 2px;
  margin-bottom: 0px;
}

.error-messages {
  font-size: 12px;
  color: #b0003a;
}

.card {

  background-color: white;
  display: inline-block;
  box-shadow: rgba(156,156,156, .5) 1px 1px 4px;
  margin-bottom: 15px;
}

.card-details {
  border-top: 1px solid #6e46a5;
  padding: 0px 20px 20px 20px;
  display: inline-block;
  min-width: 725px;
  margin: 20px;
}

legend {
  padding: 0.2em 0.5em;
  font-size:90%;
  width: auto;
  font-family: RoobertENEL-Light, sans-serif;
  color: #717171;
}

button.primary-btn, button{
  background: var(--primary-color);
  color: white;
  border: none;
  &:hover {
    cursor: pointer;
  }
}
